.MediaView{
    width: 100%;
    height: 100%;
}
.MediaContent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 60px;
}
.MediaContent img{
    margin: 14px;
    width: 92%;
}
.MediaContent a{
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 55px;
    right: 30px;
    float: right;
    padding: 4px 8px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    font-family: 'Roboto Regular';
    background: #222831;
    border-radius: 8px;
}

.MediaContent a:hover{
    background-color: #f4f4f4;
    color: #333333;
}

.width50{
    width: 50%;
}


@media only screen and (max-width: 768px) {
    .MediaContent{
        flex-direction: column;
    }
    .MediaContent img{
        margin: 7px;
        width: 95%;
    }
    .width50 {
        width: 100%;
    }

}


@media only screen and (max-width: 475px) {
    .MediaContent a{
        bottom: 47px;
        right: 20px;
        font-size: 12px;
    }
}
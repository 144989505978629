.NavNavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    height: 8vh;
    z-index: 9;
}

.NavLogo {
    height: 7vh;
    position: absolute;
    left: 24px;
    top: 3px;
}

.mobNavLogo {
    display: none;
    position: absolute;
    top: 2px;
    left: 14px;
    height: 52px;
}

.NavItems {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0px 64px;
    height: 100%;
}

.NavUl {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    height: 65px;
    clear: both;
    transition: max-height .2s ease-out;
}

.NavUl li a, .NavItem {
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 8px 32px;
    display: block;
}

.NavItem {
    padding: 8px 30px;
}

.NavActive {
    color: #FE0000 !important;
}

.NavInactive {
    color: #000000;
}

.submenuNav {
    position: relative;
    cursor: pointer;
}

.submenuNav:hover {
    border-bottom: 0.5px solid #d8d8d8;
    background: #B0BEC5;
}

.submenuItems {
    display: none;
    position: absolute;
    flex-direction: column;
    top: 39px;
    left: 0;
    background: #B0BEC5;
}

li .submenuItems a {
    border-bottom: 0.5px solid #d8d8d8;
    margin: 0;
    padding: 8px 0px;
    font-size: 16px;
    text-transform: capitalize;

}

.submenuItems a:last-child {
    border: none;
}

.submenuNav:hover .submenuItems {
    display: flex;
}

.NavNavbar li a:hover,
.NavNavbar .HamBtn:hover {
    background-color: #f4f4f4;
    color: #333333;
}


/* menu */

.NavNavbar .NavUl {
    clear: both;
    transition: max-height .2s ease-out;
}

/* menu icon */

.NavNavbar .HamIcon .NavIcon {
    background: #333;
    display: none;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.NavNavbar .HamIcon .NavIcon:before,
.NavNavbar .HamIcon .NavIcon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.NavNavbar .HamIcon .NavIcon:before {
    top: 5px;
}

.NavNavbar .HamIcon .NavIcon:after {
    top: -5px;
}

/* menu btn */

.NavNavbar .HamBtn {
    display: none;
}

.NavNavbar .HamBtn:checked~.mobNavLogo {
    display: none !important;
}

.NavNavbar .HamBtn:checked~.NavItems {
    display: flex;
}

.NavNavbar .HamBtn:checked~.HamIcon .NavIcon {
    background: transparent;
}

.NavNavbar .HamBtn:checked~.HamIcon .NavIcon:before {
    transform: rotate(-45deg);
}

.NavNavbar .HamBtn:checked~.HamIcon .NavIcon:after {
    transform: rotate(45deg);
}

.NavNavbar .HamBtn:checked~.HamIcon:not(.steps) .NavIcon:before,
.NavNavbar .HamBtn:checked~.HamIcon:not(.steps) .NavIcon:after {
    top: 0;
}

.submenuCheck {
    display: none;
}

.ConnectUs {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer {
    border-top: 1px solid #3333;
}

.ConnectUs span {
    margin-right: 4px;
}

.Copyright {
    padding-bottom: 10px;
    margin: 0;
    font-size: 12px;
}


@media only screen and (max-width: 890px) {
    .NavUl {
        width: 100%;
        justify-content: space-evenly;
    }

    .NavUl li a, .NavItem {
        padding: 8px 16px;
    }

}

@media only screen and (max-width: 768px) {
    .NavNavbar {
        height: auto;
    }

    .NavLogo {
        display: none;
    }

    .mobNavLogo {
        display: block;
    }

    .NavItems {
        padding: 18px;
        display: none;

    }

    .NavUl {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .NavUl li a, .NavItem {
        padding: 4px 24px;
        font-size: 14px;
        text-align: left;
        color: #333333;
    }

    .NavNavbar .HamIcon .NavIcon {
        display: block;
    }

    .NavUl li {
        margin: 4px 0;
    }

    .NavNavbar .HamIcon {
        cursor: pointer;
        display: inline-block;
        float: right;
        padding: 28px 20px;
        position: relative;
        user-select: none;
    }

    .NavItem::after {
        content: "❯";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all 0.35s;
        position: absolute;
        top: 4px;
        left: 3px;

    }

    .submenuCheck {
        display: block;
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    .submenuNav:hover {
        color: #d8d8d8;
        border-bottom: 0.5px solid #f4f4f4;
        background: #f4f4f4;
    }

    .submenuNav:hover .NavItem::after {
        transform: rotate(90deg);
        top: 5px;
    }

    .submenuItems {
        display: none;
        position: unset;
        flex-direction: column;
        text-align: left;
        background: #FFFFFF;
    }

    li .submenuItems a {
        color: #333333;
        border-bottom: 0.5px solid #f4f4f4;
        margin: 0;
        padding: 4px 24px;
        font-size: 16px;
        text-transform: capitalize;

    }

    .submenuItems a:last-child {
        border: none;
    }

    .submenuNav:hover .submenuItems {
        display: flex;
    }


}
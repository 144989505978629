.GalleryView{
    width: 100%;
    height: 100%;
}

.galleryItems ul {
    list-style: none;
    margin: 0;
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}

.galleryItems ul li {
    max-width: 20%;
    min-width: 260px;
    margin: 20px;
    height: auto;
    cursor: pointer;
}

.galleryItems ul li img {
    height: auto;
    width: 100%;
    max-height: 225px;
    object-fit: contain;
}

.galleryImgTile {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 230px;
    display: flex;
    align-items: center;
}

.galleryTitle {
    font-family: 'Roboto Medium';
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
    padding: 8px 0;
}

.PaintingModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ModalContent {
    background: #ffffff;
    border-radius: 2px;
    padding: 36px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ModalContent img {
    height: 80vh;
    width: auto;
    max-width: 100%;
}

.ModalClose {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

@media only screen and (max-width: 768px) {
    .galleryItems ul li{
        margin: 10px;
    }
}

@media only screen and (max-width: 475px) {
    .galleryItems ul{
        padding: 50px 0px;
    }
    .ModalContent img {
        width: 100%;
        height: auto;
    }
}
@font-face {
  font-family: "Roboto Light";
  /*Can be any text*/
  src: url("/src/assest/font/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  /*Can be any text*/
  src: url("/src/assest/font/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  /*Can be any text*/
  src: url("/src/assest/font/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  /*Can be any text*/
  src: url("/src/assest/font/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black";
  /*Can be any text*/
  src: url("/src/assest/font/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "1942 Report";
  /*Can be any text*/
  src: url("/src/assest/font/1942-Report.ttf") format("truetype");
}

.App {
  text-align: center;
  font-family: 'Roboto Regular';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.AppViews {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
  min-height: 92vh;
  width: 100%;
}

.width50 {
  width: 50%;
  display: flex;
  justify-content: center;
}

.width90 {
  width: 90%;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #939393 #d9d9d9;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;

}

*::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #939393;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 768px) {
  .AppViews {
    margin-top: 6vh;
    min-height: 94vh;
    height: initial;
  }

  .width50 {
    width: 100%;
    display: block;
  }

  .width90 {
    width: 100%;
  }
}

@media only screen and (max-width: 890px) {}
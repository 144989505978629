.PaintingView {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 64px;
    margin-top: 65px;

}

.title {
    font-family: '1942 Report';
    font-size: 54px;
    font-weight: normal;
    text-transform: capitalize;
    border-bottom: 2px solid #333333;
    padding: 70px 0px;
    margin: 0px;
}

.quote {
    font-size: 36px;
    font-weight: normal;
    border-bottom: 2px solid #333333;
    padding: 70px 0px;
    margin: 0px;
    font-family: 'Times';
    font-style: italic;
}

.galleryItems {}

.galleryItems ul {
    list-style: none;
    margin: 0;
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}

.galleryItems ul li {
    max-width: 20%;
    min-width: 260px;
    margin: 20px;
    height: auto;
    cursor: pointer;
}

.galleryItems ul li img {
    height: auto;
    width: 100%;
    max-height: 225px;
    object-fit: contain;
}

.galleryImgTile {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 230px;
    display: flex;
    align-items: center;
}

.galleryTitle {
    font-family: 'Roboto Medium';
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
    padding: 8px 0;
}

.PaintingSpecifications {
    padding: 8px;
    border: 1px solid #3333;
    border-top: none;
}

.galleryContents {
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    text-align: left;
}

.back {
    text-decoration: none;
    width: fit-content;
    cursor: pointer;
    color: #333333;
    font-family: 'Roboto Bold';
    margin-top: 30px;
}

.back svg {
    margin-right: 5px;
}

.PaintingModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ModalContent {
    background: #ffffff;
    border-radius: 2px;
    padding: 36px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ModalContent img {
    height: 80vh;
    width: auto;
    max-width: 100%;
}

.ModalClose {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

.Pricing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    width: fit-content;
    border: 1px solid #333333;
    background-color: #B0BEC5;
    border-radius: 5px;
    margin-top: 36px;
    position: sticky;
    position: -webkit-sticky;
    text-decoration: none;
    color: #333333 !important;
    font-family: 'Roboto Medium';
}

.Pricing svg {
    font-size: 20px;
    margin-right: 3px;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 36px;
        padding: 35px 0;
    }

    .quote {
        font-size: 24px;
        padding: 35px 0px;
    }

    .galleryItems ul li {
        margin: 10px;
    }
}

@media only screen and (max-width: 475px) {
    .PaintingView {
        padding: 0px 10px;
    }

    .title {
        font-size: 28px;
        padding: 25px 0px;
    }

    .quote {
        font-size: 16px;
        padding: 25px 0px;
    }

    .galleryItems ul {
        padding: 16px 0px;
    }

    .ModalContent img {
        width: 100%;
        height: auto;
    }

    .back {
        margin-top: 0;
    }

    .Pricing {
        font-size: 10px;
        padding: 3px;
        top: 58px;
        margin-top: 16px;
    }

    .Pricing svg {
        font-size: 13px;
        margin-right: 3px;
    }
}
.LogoMask {
    height: 522px;
    width: 522px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.LogoMask h3 {
    font-size: 36px;
    font-family: 'Roboto Light';

}

.ImgCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.IconBtn {
    background: #222222 !important;
    padding: 4px 8px !important;
    margin: 6px 12px !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;
}

.IconBtn .BtnText {
    font-size: 14px;
    margin-left: 4px;
}

@media only screen and (max-width: 728px) {
    .LogoMask {
        height: 350px;
        width: 350px;
    }
}
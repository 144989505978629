.PaintingsView{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PaintingsImport{
    margin-top: 65px;
}
.PaintingsBlock{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.item{
    max-width: 380px;
    width: 100%;
    height: auto;
    cursor: pointer;
    position: relative;
}
.item img{
    max-width: 380px;
    width: 100%;
    height: auto;
}
.ItemMask{
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}
.item h2{
    color: #ffffff;
    margin: 0;
    font-family: '1942 Report';
    font-size: 40px;
    line-height: 40px;
    font-weight: 400;
    max-width: 200px;
}

@media only screen and (max-width: 768px) {
    .PaintingsBlock{
        margin-top: 58px;
        flex-direction: column;
    }
    .item{
        margin: 8px 0;
    }

}
@media only screen and (max-width: 475px) {
    .item h2{
        font-size: 30px;
    }
}
.LogoMask{
    height: 522px;
    width: 522px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    position: relative;
}
.ImgCenter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media only screen and (max-width: 728px) {
    .LogoMask{
        height: 350px;
        width: 350px;
    }
    .ImgCenter{
        height: 225px;
        width: 225px;
    }
}

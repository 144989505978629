.ShowsNavbar {
    position: fixed;
    top: 8vh;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    height: 5vh;
    display: flex;
    align-items: center;
    box-shadow: inset 0px -1px 0px rgb(0 0 0 / 12%);
    z-index: 8;
}

.NavUl {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.NavUl li a {
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin: 8px 32px;
}

.NavActive {
    color: #FE0000;
}

.NavInactive {
    color: #000000;
}

@media only screen and (max-width: 768px) {
    .ShowsNavbar {
        top: 58px;
        height: 42px;
    }

    .NavUl li a {
        font-size: 14px;
        line-height: 20px;
        margin: 4px 24px;
    }
}

@media only screen and (max-width: 475px) {
    .ShowsNavbar{
        height: 40px;
        justify-content: center;
    }
    .NavUl li a {
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        margin: 4px 8px;
    }
}
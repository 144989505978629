.Aboutbg {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 64px;
}

.AboutBody {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}

.AboutContent {
    max-height: 75vh;
    overflow-y: auto;
}

.leftContent img {
    margin: 1.3em 0;
    width: 100%;
    height: auto;
    max-width: 320px;
    pointer-events: none;
}

.title {
    font-family: '1942 Report';
    font-size: 40px;
}

.tagLine {
    font-family: 'Roboto Regular';
    font-size: 18px;
}

.tagLine span {
    font-family: '1942 Report';
    padding: 0px 5px;
}

.AboutContent p {
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
}

.Aboutbg h1 {
    font-size: 80px;
    font-weight: 200;
    font-family: '1942 Report';
    text-transform: uppercase;
    margin: 24px 0;
    text-align: center;
}

.VenueContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}

.Circle {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    border: 1px solid #333333;
    display: flex;
    align-items: center;
    padding: 5px;
}

.Circle span {
    padding: 0px 15px;
    text-transform: uppercase;
    font-family: 'Roboto Bold';
    font-size: 14px;
    text-align: center;
}

.VenueContentUl {
    text-align: left;
    list-style: none;
    border-left: 1px solid;
    margin-left: 32px;
    padding-left: 32px;
}

.VenueContentUl li {
    padding: 8px 0px;
}

.VenueContentUl li div {
    font-family: 'Roboto Bold';
    font-size: 16px;
}

.VenueContentUl li span {
    font-family: 'Roboto Regular';
    font-size: 16px;
    display: block;
    padding-top: 2px
}

@media only screen and (max-width: 1024px) {
    .Aboutbg {
        padding: 80px 12px 0px;
    }
}

@media only screen and (max-width: 890px) {
    .Aboutbg h1 {
        padding: 0px;
        font-size: 60px;
        width: 100%;
        text-align: center;
    }

    .Aboutbg {
        padding: 80px 12px 0px;
        height: initial;
    }
}

@media only screen and (max-width: 768px) {

    .AboutBody {
        flex-direction: column;
    }

    .AboutContent {
        max-height: initial;
    }
    .AboutContent p {
        font-size: 14px;
        line-height: 20px;
    }
}
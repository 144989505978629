.Aboutbg {
    margin-top: 7vh;
    height: 84vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.AboutContent {
    max-height: 75vh;
    overflow-y: auto;
}

.AboutBody img {
    margin: 1.3em 0;
    width: 100%;
    height: auto;
    pointer-events: none;
}

.AboutContent p {
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
}

.Aboutbg h1 {
    font-size: 80px;
    font-weight: 200;
    font-family: '1942 Report';
    text-transform: uppercase;
    margin: 24px 0;
    text-align: center;
}

@media only screen and (max-width: 890px) {
    .Aboutbg h1 {
        padding: 0px;
        font-size: 60px;
        width: 100%;
        text-align: center;
    }

    .Aboutbg {
        padding: 80px 12px 0px;
        height: initial;
    }
}

@media only screen and (max-width: 768px) {

    .AboutBody {
        flex-direction: column;
    }

    .AboutContent {
        max-height: initial;
    }
}